body {
  overscroll-behavior-y: none;
}

html,
body,
div#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}
